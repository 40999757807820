import { getDpFormat } from '@hailstonelabs/big-number-utils'
import { ColumnDef } from '@tanstack/react-table'
import { BigNumber, ethers } from 'ethers'
import { useMemo, useState } from 'react'
import { ZERO_ADDRESS } from '../../../../constants/common'
import { MASTER_WOMBATS, VOTERS } from '../../../../constants/contract'
import { BOOSTED_MULTI_REWARDER_ABI } from '../../../../constants/contract/abis/boostedMultiRewarder'
import { useModalContext } from '../../../../context/ModalContext'
import { useVotingData as useContextVotingData } from '../../../../context/VotingDataContext'
import { useWeb3 } from '../../../../context/Web3Context'
import { ErrorMessages, PROVIDER_ERROR_CODES } from '../../../../context/errorMessage'
import { AdminTableColumnId, IEpochEmissionData } from '../../../../interfaces/admin'
import { ModalId } from '../../../../interfaces/modal'
import { useVotingData } from '../../../../store/MulticallData/hooks'
import { useTokenPrices } from '../../../../store/Prices/hooks'
import { addErrorToast } from '../../../../store/Toast/actions'
import { useAppDispatch } from '../../../../store/hooks'
import { calculateGasMargin } from '../../../../utils'
import Button from '../../../Button'
import { ChainInfoButton } from '../../../ChainInfoButton'
import SwitchNetworkModal from '../../../SwitchNetwork'
import TooltipNum from '../../../TooltipNum'
import { useAdminDataContext } from '../../AdminDataContext'
import { ADMIN_TABLE_COLUMN_NAME, MAX_BRIBE_RUNOUT_DAYS } from '../../constants'
import { TanTable } from '../TanTable'
import { CopyAndExplorerTab, HeaderWithTotalValue, NumberTab } from './element'

export const AdminTable = ({ handleClose }: { handleClose: () => void }) => {
  const {
    toggleRequiresBribeAlmostRunout,
    requiresBribeAlmostRunout,
    epochEmissionData: { metaData, tableData },
  } = useAdminDataContext()
  const tokenPrices = useTokenPrices()
  const votingData = useVotingData()
  const { chainId, chainInfo, signer } = useWeb3()
  const [isOpenSwitchNetworkModal, setIsOpenSwitchNetworkModal] = useState<boolean>(false)
  const [isUpdatingBribeRewardRate, setIsUpdatingBribeRewardRate] = useState(false)
  const { nextEpochStartTime } = useContextVotingData()
  const dispatch = useAppDispatch()
  const {
    actions: { openModal },
  } = useModalContext()

  const findBalanceInfo = <
    T extends
      | IEpochEmissionData[AdminTableColumnId.poolRewardersBalanceInfos]
      | IEpochEmissionData[AdminTableColumnId.boostedRewardersBalanceInfos]
      | IEpochEmissionData[AdminTableColumnId.bribeBalanceInfos]
  >(
    balanceInfos: T,
    tokenSymbol: string
  ) => {
    if (balanceInfos) {
      for (let i = 0; i < balanceInfos?.length; i++) {
        if (balanceInfos[i].tokenSymbol === tokenSymbol) {
          return balanceInfos[i]
        }
      }
    }
    return undefined
  }

  const columns = useMemo<ColumnDef<IEpochEmissionData>[]>(
    () => [
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.poolInfo],
        accessorKey: AdminTableColumnId.poolInfo,
        cell: (row) => {
          const poolInfo = row.renderValue() as IEpochEmissionData[AdminTableColumnId.poolInfo]
          return (
            <CopyAndExplorerTab address={poolInfo.address} chainId={poolInfo.chainId}>
              {poolInfo.name}
            </CopyAndExplorerTab>
          )
        },
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.assetInfo],
        accessorKey: AdminTableColumnId.assetInfo,
        cell: (row) => {
          const assetInfo = row.renderValue() as IEpochEmissionData[AdminTableColumnId.assetInfo]
          return (
            <div>
              <CopyAndExplorerTab address={assetInfo.address} chainId={assetInfo.chainId}>
                {assetInfo.tokenSymbol}
              </CopyAndExplorerTab>
              {assetInfo.boostedPoolRewarder && assetInfo.boostedPoolRewarder !== ZERO_ADDRESS && (
                <CopyAndExplorerTab
                  address={assetInfo.boostedPoolRewarder}
                  chainId={assetInfo.chainId}
                >
                  <span className="text-left leading-4">
                    <div>Boosted Pool</div>
                    <div>Rewarder</div>
                  </span>
                </CopyAndExplorerTab>
              )}
              {assetInfo.bribeRewarderAddress && assetInfo.bribeRewarderAddress !== ZERO_ADDRESS && (
                <CopyAndExplorerTab
                  address={assetInfo.bribeRewarderAddress}
                  chainId={assetInfo.chainId}
                >
                  <span className="text-left leading-4">
                    <div>Bribe</div>
                    <div>Rewarder</div>
                  </span>
                </CopyAndExplorerTab>
              )}
              {assetInfo.poolRewarderAddress && assetInfo.poolRewarderAddress !== ZERO_ADDRESS && (
                <CopyAndExplorerTab
                  address={assetInfo.poolRewarderAddress}
                  chainId={assetInfo.chainId}
                >
                  <span className="text-left leading-4">
                    <div>Pool</div>
                    <div>Rewarder</div>
                  </span>
                </CopyAndExplorerTab>
              )}
            </div>
          )
        },
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.pid],
        accessorKey: AdminTableColumnId.pid,
      },
      {
        header: () => (
          <HeaderWithTotalValue
            title={ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.poolRewardersEmissionInCurrentEpochs]}
            totalValueInUsd={metaData.sumOfPoolRewardersEmissionInCurrentEpoch.inUsd || ''}
          />
        ),
        accessorKey: AdminTableColumnId.poolRewardersEmissionInCurrentEpochs,
        cell: (row) => {
          const poolRewardersEmissionInCurrentEpochs =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.poolRewardersEmissionInCurrentEpochs]

          return poolRewardersEmissionInCurrentEpochs.map(
            ({ bonusTokenPerWeek, symbol, rewardTokenPrice }, index) => {
              return (
                <NumberTab
                  key={index}
                  topLeft={{
                    value: bonusTokenPerWeek,
                    symbol: {
                      isleft: false,
                      value: symbol,
                    },
                  }}
                  bottomLeft={{
                    value: bonusTokenPerWeek * rewardTokenPrice,
                    symbol: {
                      isleft: true,
                      value: '$',
                    },
                  }}
                  right={{
                    value:
                      (bonusTokenPerWeek * rewardTokenPrice * 100) /
                      metaData.sumOfPoolRewardersEmissionInCurrentEpoch.inUsd,
                    symbol: {
                      isleft: false,
                      value: '%',
                    },
                  }}
                />
              )
            }
          )
        },
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.poolRewardersBalanceInfos],
        accessorKey: AdminTableColumnId.poolRewardersBalanceInfos,
        cell: (row) => {
          const poolRewarderBalanceInfos =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.poolRewardersBalanceInfos]
          return (
            <>
              {poolRewarderBalanceInfos.map((balanceInfo, i) => (
                <div key={`poolRewarderBalanceInfo_${i}`}>
                  {`${balanceInfo.amount}${balanceInfo.tokenSymbol}`}(
                  {balanceInfo.isTpsZero ? (
                    'TPS=0'
                  ) : (
                    <span
                      className={
                        balanceInfo.runoutDays < MAX_BRIBE_RUNOUT_DAYS ? ' text-red-500' : ''
                      }
                    >
                      <TooltipNum amount={balanceInfo.runoutDays} /> days
                    </span>
                  )}
                  )
                </div>
              ))}
            </>
          )
        },
      },
      {
        header: () => (
          <HeaderWithTotalValue
            title={
              ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.boostedRewardersEmissionInCurrentEpochs]
            }
            totalValueInUsd={metaData.sumOfBoostedRewardersEmissionInCurrentEpoch.inUsd || ''}
          />
        ),
        accessorKey: AdminTableColumnId.boostedRewardersEmissionInCurrentEpochs,
        cell: (row) => {
          const boostedRewardersEmissionInCurrentEpochs =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.boostedRewardersEmissionInCurrentEpochs]

          return boostedRewardersEmissionInCurrentEpochs?.map(
            ({ bonusTokenPerWeek, symbol, rewardTokenPrice }, index) => {
              return (
                <NumberTab
                  key={index}
                  topLeft={{
                    value: bonusTokenPerWeek,
                    symbol: {
                      isleft: false,
                      value: symbol,
                    },
                  }}
                  bottomLeft={{
                    value: bonusTokenPerWeek * rewardTokenPrice,
                    symbol: {
                      isleft: true,
                      value: '$',
                    },
                  }}
                  right={{
                    value:
                      (bonusTokenPerWeek * rewardTokenPrice * 100) /
                      metaData.sumOfBoostedRewardersEmissionInCurrentEpoch.inUsd,
                    symbol: {
                      isleft: false,
                      value: '%',
                    },
                  }}
                />
              )
            }
          )
        },
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.boostedRewardersBalanceInfos],
        accessorKey: AdminTableColumnId.boostedRewardersBalanceInfos,
        cell: (row) => {
          const boostedRewarderBalanceInfos =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.boostedRewardersBalanceInfos]
          return (
            <>
              {boostedRewarderBalanceInfos?.map((balanceInfo, i) => (
                <div key={`boostedRewarderBalanceInfo_${i}`}>
                  {`${balanceInfo.amount}${balanceInfo.tokenSymbol}`}(
                  {balanceInfo.isTpsZero ? (
                    'TPS=0'
                  ) : (
                    <span
                      className={
                        balanceInfo.runoutDays < MAX_BRIBE_RUNOUT_DAYS ? ' text-red-500' : ''
                      }
                    >
                      <TooltipNum amount={balanceInfo.runoutDays} /> days
                    </span>
                  )}
                  )
                </div>
              ))}
            </>
          )
        },
      },
      {
        header: () => (
          <HeaderWithTotalValue
            title={ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.womEmissionInCurrentEpoch]}
            totalValue={metaData.sumOfWomEmissionInCurrentEpoch.inWom || ''}
            totalValueInUsd={metaData.sumOfWomEmissionInCurrentEpoch.inUsd || ''}
          />
        ),
        cell: (row) => {
          const womEmissionInCurrentEpoch =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.womEmissionInCurrentEpoch]

          return (
            <NumberTab
              topLeft={{
                value: womEmissionInCurrentEpoch,
              }}
              bottomLeft={{
                value: womEmissionInCurrentEpoch * (tokenPrices.WOM ? Number(tokenPrices.WOM) : 1),
                symbol: {
                  isleft: true,
                  value: '$',
                },
              }}
              right={{
                value:
                  (Number(womEmissionInCurrentEpoch) * 100) /
                  Number(metaData.sumOfWomEmissionInCurrentEpoch.inWom),
                symbol: {
                  isleft: false,
                  value: '%',
                },
              }}
            />
          )
        },
        accessorKey: AdminTableColumnId.womEmissionInCurrentEpoch,
      },
      {
        header: () => (
          <HeaderWithTotalValue
            title={ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.womEmissionInNextEpoch]}
            totalValue={metaData.sumOfWomEmissionInNextEpoch.inWom}
            totalValueInUsd={metaData.sumOfWomEmissionInNextEpoch.inUsd}
          />
        ),
        cell: (row) => {
          const womEmissionInNextEpoch =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.womEmissionInNextEpoch]
          return (
            <NumberTab
              topLeft={{
                value: womEmissionInNextEpoch,
              }}
              bottomLeft={{
                value: womEmissionInNextEpoch * (tokenPrices.WOM ? Number(tokenPrices.WOM) : 1),
                symbol: {
                  isleft: true,
                  value: '$',
                },
              }}
              right={{
                value: (womEmissionInNextEpoch * 100) / metaData.sumOfWomEmissionInNextEpoch.inWom,
                symbol: {
                  isleft: false,
                  value: '%',
                },
              }}
            />
          )
        },
        accessorKey: AdminTableColumnId.womEmissionInNextEpoch,
      },
      {
        header: () => (
          <HeaderWithTotalValue
            title={ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.allocPoint]}
            totalValue={votingData.withoutAccount?.totalAllocPoint || ''}
          />
        ),
        cell: (row) => {
          const allocationPoint =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.allocPoint]
          return (
            <NumberTab
              topLeft={{
                value: allocationPoint,
              }}
              right={{
                value:
                  (Number(allocationPoint) * 100) /
                  Number(votingData.withoutAccount?.totalAllocPoint),
                symbol: {
                  isleft: false,
                  value: '%',
                },
              }}
            />
          )
        },
        accessorKey: AdminTableColumnId.allocPoint,
      },
      {
        header: () => (
          <HeaderWithTotalValue
            title={ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.gaugeWeight]}
            totalValue={metaData.sumOfGaugeWeight || ''}
          />
        ),
        cell: (row) => {
          const gaugeWeight =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.gaugeWeight]
          return (
            <NumberTab
              topLeft={{
                value: gaugeWeight,
              }}
              right={{
                value: (Number(gaugeWeight) * 100) / Number(metaData.sumOfGaugeWeight),
                symbol: {
                  isleft: false,
                  value: '%',
                },
              }}
            />
          )
        },
        accessorKey: AdminTableColumnId.gaugeWeight,
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.bribeInfo],
        accessorKey: AdminTableColumnId.bribeInfo,
        cell: (row) => {
          const bribeInfo = row.renderValue() as IEpochEmissionData[AdminTableColumnId.bribeInfo]
          return (
            <div>
              {bribeInfo.map((info, i) => (
                <CopyAndExplorerTab address={info.address} chainId={info.chainId} key={`info_${i}`}>
                  {info.tokenSymbol}
                </CopyAndExplorerTab>
              ))}
            </div>
          )
        },
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.bribeInCurrentEpoch],
        accessorKey: AdminTableColumnId.bribeInCurrentEpoch,
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.totalBribeInUsdInCurrentEpoch],
        accessorKey: AdminTableColumnId.totalBribeInUsdInCurrentEpoch,
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.bribeEfficiency],
        accessorKey: AdminTableColumnId.bribeEfficiency,
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.projectedBribeEfficiency],
        accessorKey: AdminTableColumnId.projectedBribeEfficiency,
      },
      {
        header: () => (
          <button onClick={toggleRequiresBribeAlmostRunout}>
            Bribe balances {requiresBribeAlmostRunout ? '❕' : '❗️'}
          </button>
        ),
        accessorKey: AdminTableColumnId.bribeBalanceInfos,
        cell: (row) => {
          const bribeBalanceInfos =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.bribeBalanceInfos]
          return (
            <>
              {bribeBalanceInfos.map((bribeBalanceInfo, i) => (
                <div key={`bribeBalanceInfo_${i}`}>
                  {`${bribeBalanceInfo.amount}${bribeBalanceInfo.tokenSymbol}`}(
                  {bribeBalanceInfo.bribeRunoutDays == -1 ? (
                    'TPS=0'
                  ) : (
                    <span
                      className={
                        bribeBalanceInfo.bribeRunoutDays < MAX_BRIBE_RUNOUT_DAYS
                          ? ' text-red-500'
                          : ''
                      }
                    >
                      <TooltipNum amount={bribeBalanceInfo.bribeRunoutDays} /> days
                    </span>
                  )}
                  )
                </div>
              ))}
            </>
          )
        },
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.ampFactor],
        accessorKey: AdminTableColumnId.ampFactor,
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.haircutPercentage],
        accessorKey: AdminTableColumnId.haircutPercentage,
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.remainingSurplus],
        accessorKey: AdminTableColumnId.remainingSurplus,
        cell: (row) => {
          const surplusData =
            row.renderValue() as IEpochEmissionData[AdminTableColumnId.remainingSurplus]
          const poolRewardersBalanceInfos = tableData[row.row.index][
            AdminTableColumnId.poolRewardersBalanceInfos
          ] as IEpochEmissionData[AdminTableColumnId.poolRewardersBalanceInfos]

          const boostedRewardersBalanceInfos = tableData[row.row.index][
            AdminTableColumnId.boostedRewardersBalanceInfos
          ] as IEpochEmissionData[AdminTableColumnId.boostedRewardersBalanceInfos]

          const bribeBalanceInfos = tableData[row.row.index][
            AdminTableColumnId.bribeBalanceInfos
          ] as IEpochEmissionData[AdminTableColumnId.bribeBalanceInfos]
          return (
            <div>
              {surplusData && (
                <span>
                  {surplusData.map((data) => {
                    const title = data.is_rewarder
                      ? `Rewarder ${data.is_v1 ? '(v1)' : ''}:`
                      : 'Bribe:'
                    const balanceInfos = data.is_rewarder
                      ? data.is_v1
                        ? poolRewardersBalanceInfos
                        : boostedRewardersBalanceInfos
                      : bribeBalanceInfos
                    return (
                      <div key={data.address}>
                        <span>{title}</span>
                        {data.surplus?.map((s, index) => {
                          const value = Number(s.value)
                          const balanceInfo = findBalanceInfo(balanceInfos, s.symbol)

                          return (
                            <div key={`${s.symbol}_${index}`}>
                              <span className={`ml-2 ${value <= 0 ? 'text-red-500' : ''}`}>
                                {value.toFixed(4)} {s.symbol} (
                                {balanceInfo
                                  ? balanceInfo.tokensPerDays === 0
                                    ? 'TPS=0'
                                    : `${(value / balanceInfo.tokensPerDays).toFixed(2)} days`
                                  : ''}
                                )
                              </span>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </span>
              )}
            </div>
          )
        },
      },
      {
        header: ADMIN_TABLE_COLUMN_NAME[AdminTableColumnId.rates],
        accessorKey: AdminTableColumnId.rates,
        cell: (row) => {
          const rates = row.renderValue() as IEpochEmissionData[AdminTableColumnId.rates]

          const onSetRate = async (tokenId: number, tokenPerSec: string) => {
            try {
              if (!signer) throw new Error('Signer is not found!')
              const boostedRewarderContract = new ethers.Contract(
                rates.boostedPoolRewarder,
                BOOSTED_MULTI_REWARDER_ABI,
                signer
              )

              const tokenPerSecBn = BigNumber.from(tokenPerSec)

              const setRewardRateFnArgs: [BigNumber, BigNumber, BigNumber] = [
                BigNumber.from(tokenId),
                tokenPerSecBn,
                BigNumber.from(0),
              ]

              if (boostedRewarderContract) {
                const estimatedGas = await boostedRewarderContract.estimateGas.setRewardRate(
                  ...setRewardRateFnArgs
                )
                const transaction = await boostedRewarderContract.setRewardRate(
                  ...setRewardRateFnArgs,
                  {
                    gasLimit: calculateGasMargin(estimatedGas),
                  }
                )
                const receipt = await transaction.wait()
                openModal({
                  currentModalId: ModalId.TRANSACTION_SUBMITTED,
                  payload: { transactionHashes: [receipt.transactionHash] },
                })
              }
            } catch (err) {
              const code = PROVIDER_ERROR_CODES.REQUEST_DENIED_ERROR
              if (JSON.stringify(err).includes(code)) {
                const errorMessage = ErrorMessages[code]
                dispatch(
                  addErrorToast({
                    message: errorMessage.message,
                    title: errorMessage.title,
                  })
                )
              } else {
                const error = err as Error
                const errorMessage = error.message || 'Failed to update reward rate.'
                dispatch(
                  addErrorToast({
                    message: errorMessage,
                  })
                )
              }
            } finally {
              setIsUpdatingBribeRewardRate(false)
            }
          }
          return (
            <>
              {rates.boostedPoolRewarder && rates.boostedPoolRewarder !== ZERO_ADDRESS && (
                <div>
                  {rates.values.map((rate) => {
                    return (
                      <>
                        <strong>{rate.tokenSymbol}</strong>
                        <p>Current Rate: {rate.currentRate}</p>
                        <p>Expected Rate: {rate.expectedRate}</p>
                        <Button
                          isLoading={isUpdatingBribeRewardRate}
                          onClick={() => onSetRate(rate.tokenId, rate.expectedRate)}
                          className="swap-USDT-USDC-connect-wallet button-hover-opacity-light false relative mt-2 flex  h-11 w-full items-center justify-center bg-linearGradientApprove"
                        >
                          <span className="font-Jamjuree text-base font-bold text-wombatCream">
                            {isUpdatingBribeRewardRate ? 'Setting...' : 'Set Rate'}
                          </span>
                        </Button>
                        <hr className="my-3" />
                      </>
                    )
                  })}
                </div>
              )}
            </>
          )
        },
      },
    ],
    [
      dispatch,
      isUpdatingBribeRewardRate,
      metaData.sumOfBoostedRewardersEmissionInCurrentEpoch.inUsd,
      metaData.sumOfGaugeWeight,
      metaData.sumOfPoolRewardersEmissionInCurrentEpoch.inUsd,
      metaData.sumOfWomEmissionInCurrentEpoch.inUsd,
      metaData.sumOfWomEmissionInCurrentEpoch.inWom,
      metaData.sumOfWomEmissionInNextEpoch.inUsd,
      metaData.sumOfWomEmissionInNextEpoch.inWom,
      openModal,
      requiresBribeAlmostRunout,
      signer,
      tableData,
      toggleRequiresBribeAlmostRunout,
      tokenPrices.WOM,
      votingData.withoutAccount?.totalAllocPoint,
    ]
  )

  return (
    <div className="px-5 font-Roboto ">
      <div className="sticky top-0 z-10  bg-white p-2">
        <div className="flex justify-between">
          <div className="space-x-4">
            <span>1.Click header to sort :D</span>
            <span>2.TPS(Token Per Second)</span>
            <span>3.Click symbol to copy address or view on explorer</span>
            <span>4.Click ❗️ to filter run out bribe</span>
          </div>
          <div className="flex space-x-4">
            <ChainInfoButton
              chainInfo={chainInfo}
              networkBoxButtonClicked={() => setIsOpenSwitchNetworkModal(true)}
            />
            <Button className="w-20" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
        <div className="flex gap-4 pb-4">
          <div>
            Current Total Bribe Efficiency: {getDpFormat(metaData.totalBribeEfficiency.current, 2)}%
          </div>
          <div>
            Projected Total Bribe Efficiency:{' '}
            {nextEpochStartTime ? getDpFormat(metaData.totalBribeEfficiency.projected, 2) : '-'}%
          </div>
        </div>
        <div className="flex space-x-4">
          <CopyAndExplorerTab address={VOTERS[chainId]?.address || ''} chainId={chainId}>
            Voter
          </CopyAndExplorerTab>
          <CopyAndExplorerTab
            address={MASTER_WOMBATS[chainId]?.BOOSTED?.address || ''}
            chainId={chainId}
          >
            Boosted Master Wombat
          </CopyAndExplorerTab>
          {MASTER_WOMBATS[chainId]?.MW2?.address && (
            <CopyAndExplorerTab
              address={MASTER_WOMBATS[chainId]?.MW2?.address || ''}
              chainId={chainId}
            >
              Master Wombat V2
            </CopyAndExplorerTab>
          )}
          <span>
            Please reach out to the tech team if the bribery efficiency is small. They can execute
            multiple reward claims to activate the next gauge epoch.
          </span>
        </div>
      </div>

      <TanTable
        className="text-[14px]"
        columns={columns}
        data={tableData}
        disableSortIds={[AdminTableColumnId.bribeBalanceInfos]}
      />
      <SwitchNetworkModal
        isOpen={isOpenSwitchNetworkModal}
        onClose={() => setIsOpenSwitchNetworkModal(false)}
        isHideMobileNav
      />
    </div>
  )
}
