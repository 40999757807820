import { getCommifiedFormat, strToWad } from '@hailstonelabs/big-number-utils'
import React from 'react'
import Modal from '.'
import { TOKENS } from '../../constants/contract'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
// import { useMigrationDetectionContext } from '../../context/MigrationDetectionContext'
import { useModalContext } from '../../context/ModalContext'
import { useWeb3 } from '../../context/Web3Context'
import useMasterWombatMigrate from '../../hooks/migration/useMasterWombatMigrate'
import { ModalId } from '../../interfaces/modal'
import { WarningIcon } from '../images'
import ScrollableBox from '../ScrollableBox'
import TokenImage from '../TokenImage'
import WButton, { Variant } from '../WButton'
import { TokenSymbolStringType } from '../../interfaces/common'
import { utils } from 'ethers'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import AppTooltip from '../Tooltip/AppTooltip'
import { useMasterWombatMigrationData } from '../../store/MigrationDetection/hooks'
type Props = {
  isOpen: boolean
  onClose: () => void
}

function MigrationModal({ isOpen, onClose }: Props) {
  const { modalState } = useModalContext()
  // const { masterWombatMigrationData } = useMigrationDetectionContext()
  const masterWombatMigrationData = useMasterWombatMigrationData()
  const { handleMigrate, isWaiting: isWaitingToMigrate } = useMasterWombatMigrate()
  if (modalState.currentModalId !== ModalId.MIGRATION_WAIT_FOR_CONFIRMATION) {
    return <></>
  }
  const masterWombatId = modalState.payload.masterWombatId
  const migrationData = masterWombatMigrationData[masterWombatId]

  if (!migrationData || !migrationData.isMigrationNeeded) return <></>
  const displayData = (() => {
    const totalStakedLpDataObj: Partial<TokenSymbolStringType> = {}
    const totalRewardDataObj: Partial<TokenSymbolStringType> = {}
    if (!migrationData.stakedLpInTermsOfToken) return { totalStakedLpData: [], totalRewardData: [] }
    Object.entries(migrationData.stakedLpInTermsOfToken).forEach(
      ([poolSymbolStr, stakedLpOfEachToken]) => {
        Object.entries(stakedLpOfEachToken).forEach(([assetTokenSymbolStr, stakedLp]) => {
          // StakedLp
          const assetTokenSymbol = assetTokenSymbolStr as TokenSymbol
          totalStakedLpDataObj[assetTokenSymbol] = utils.formatEther(
            strToWad(totalStakedLpDataObj[assetTokenSymbol]).add(strToWad(stakedLp))
          )
          // Rewards
          const rewardsOfEachAsset =
            migrationData.pendingTokens?.[poolSymbolStr as PoolLabels]?.[assetTokenSymbol]
          if (rewardsOfEachAsset) {
            for (const [rewardTokenSymbolStr, rewardAmount] of Object.entries(rewardsOfEachAsset)) {
              const rewardAmountWad = strToWad(rewardAmount)
              if (rewardAmountWad.isZero()) continue
              const rewardTokenSymbol = rewardTokenSymbolStr as TokenSymbol
              totalRewardDataObj[rewardTokenSymbol] = utils.formatEther(
                strToWad(totalRewardDataObj[rewardTokenSymbol]).add(rewardAmountWad)
              )
            }
          }
        })
      }
    )
    const transformedTotalStakedLpData = Object.entries(totalStakedLpDataObj).map(
      ([tokenSymbol, value]) => {
        return {
          tokenSymbol: tokenSymbol as TokenSymbol,
          value,
        }
      }
    )
    const transformedTotalRewardData = Object.entries(totalRewardDataObj).map(
      ([tokenSymbol, value]) => {
        return {
          tokenSymbol: tokenSymbol as TokenSymbol,
          value,
        }
      }
    )
    return {
      totalStakedLpData: transformedTotalStakedLpData,
      totalRewardData: transformedTotalRewardData,
    }
  })()
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCenterTitle isOverflowHidden variant="secondary">
      <div className="relative flex flex-col items-center gap-4 font-Work text-wombatBrown1">
        <div className="flex flex-col items-center font-semibold text-wombatRed">
          <WarningIcon />
          <p>Action Required</p>
        </div>

        <p className="text-center text-sm">
          To continue earning rewards on Wombat, you must migrate your funds to the new contract.
        </p>
        <ScrollableBox
          scrollDirection="vertical"
          className="max-h-[400px] w-full rounded-[16px] border-1 border-wombatBrown1"
          style={{ padding: '14px' }}
        >
          <ItemsContainer title="Funds in old contract:" items={displayData.totalStakedLpData} />
          <hr className="m-auto my-4 h-0.5 w-[80%] bg-wombatBrown" />
          <ItemsContainer title="Pending rewards:" items={displayData.totalRewardData} />
        </ScrollableBox>

        <WButton
          variant={Variant.RED}
          width="w-[160px]"
          onClick={() => {
            {
              handleMigrate(masterWombatId)
            }
          }}
          isLoading={isWaitingToMigrate}
        >
          CLAIM & MIGRATE
        </WButton>
      </div>
    </Modal>
  )
}

export default MigrationModal

type Item = {
  tokenSymbol: TokenSymbol
  value: string
}
type ItemsContainerProps = {
  title: string
  items: Item[]
}
function ItemsContainer({ title, items }: ItemsContainerProps) {
  const { chainId } = useWeb3()
  return (
    <div>
      <p className="text-center font-semibold">{title}</p>
      {items.map((item) => {
        const token = TOKENS[chainId][item.tokenSymbol]
        const displaySymbol = token?.displaySymbol || item.tokenSymbol
        return (
          <div
            key={item.tokenSymbol}
            className="my-1 flex flex-row justify-between text-sm font-normal"
          >
            <p className="flex items-center gap-1">
              <TokenImage tokenSymbol={item.tokenSymbol} width="16" height="16" />
              <span>{displaySymbol}</span>
            </p>
            <p>
              <AppTooltip message={`${item.value} ${displaySymbol}`}>
                {getCommifiedFormat(item.value, 2)} {displaySymbol}
              </AppTooltip>
            </p>
          </div>
        )
      })}
    </div>
  )
}
