// Table.tsx

import { ColumnDef, getSortedRowModel } from '@tanstack/react-table'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

interface ReactTableProps<T extends object> {
  data: T[]
  columns: ColumnDef<T>[]
  className?: string
  sortRequired?: boolean
  disableSortIds?: string[]
}

export const TanTable = <T extends object>({
  data,
  columns,
  className,
  disableSortIds = [],
  sortRequired = true,
}: ReactTableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <div className={`${className}`}>
      <table>
        <thead className=" sticky top-[90px] z-10 bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="max-w-[140px] p-2 capitalize"
                  key={header.id}
                  onClick={
                    sortRequired && !disableSortIds.includes(header.id)
                      ? header.column.getToggleSortingHandler()
                      : undefined
                  }
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                  {{
                    asc: ' 🔼',
                    desc: ' 🔽',
                  }[header.column.getIsSorted() as string] ?? null}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td className="max-w-[150px] break-words border border-slate-700 p-2" key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
