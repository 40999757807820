import { getDpFormat, getPercentageFromTwoWAD, strToWad } from '@hailstonelabs/big-number-utils'
import axios from 'axios'
import { utils } from 'ethers'
import { ReactElement, createContext, useContext, useEffect, useMemo, useState } from 'react'
import { SECONDS_PER_WEEK, WAD_DECIMALS } from '../../constants/common'
import { TOKENS } from '../../constants/contract'
import { ASSETS } from '../../constants/contract/asset'
import { LATEST_MASTER_WOMBATS } from '../../constants/contract/masterWombat'
import { POOLS } from '../../constants/contract/pool'
import { PoolLabels } from '../../constants/contract/pool/PoolLabels'
import { TokenSymbol } from '../../constants/contract/token/TokenSymbols'
import { useVotingData as useContextVotingData } from '../../context/VotingDataContext'
import { useWeb3 } from '../../context/Web3Context'
import useToggle from '../../hooks/useToggle'
import {
  AdminTableColumnId,
  IEpochEmissionData,
  SurplusData,
  rewardersEmissionInCurrentEpoch,
} from '../../interfaces/admin'
import {
  useBoostedPoolRewarderData,
  useMasterWombatData,
  usePoolData,
  useVotingData,
} from '../../store/MulticallData/hooks'
import { useTokenPrices } from '../../store/Prices/hooks'
import { getBribeEfficiencyPercentage } from '../../utils/admin'
import { getNextEpochStartTime } from '../../utils/utils'
import { getTotalBribeEfficiencyWad } from '../../utils/voting'
import { MAX_BRIBE_RUNOUT_DAYS } from './constants'

type IAdminDataContextReturn = {
  requiresBribeAlmostRunout: boolean
  toggleRequiresBribeAlmostRunout: () => void
  epochEmissionData: {
    metaData: {
      sumOfGaugeWeight: number
      sumOfPoolRewardersEmissionInCurrentEpoch: { inUsd: number }
      sumOfBoostedRewardersEmissionInCurrentEpoch: { inUsd: number }
      sumOfWomEmissionInCurrentEpoch: { inUsd: number; inWom: number }
      sumOfWomEmissionInNextEpoch: { inUsd: number; inWom: number }
      totalBribeEfficiency: { current: string; projected: string }
    }
    tableData: IEpochEmissionData[]
  }
}
const initIAdminDataContextReturn = {
  requiresBribeAlmostRunout: false,
  toggleRequiresBribeAlmostRunout: () => {
    return
  },
  epochEmissionData: {
    metaData: {
      sumOfGaugeWeight: -1,
      sumOfWomEmissionInCurrentEpoch: {
        inUsd: -1,
        inWom: -1,
      },
      sumOfPoolRewardersEmissionInCurrentEpoch: {
        inUsd: -1,
      },
      sumOfBoostedRewardersEmissionInCurrentEpoch: {
        inUsd: -1,
      },
      sumOfWomEmissionInNextEpoch: {
        inUsd: -1,
        inWom: -1,
      },
      totalBribeEfficiency: {
        current: '',
        projected: '',
      },
    },
    tableData: [],
    surplusesData: [],
  },
}
const AdminDataContext = createContext<IAdminDataContextReturn>(initIAdminDataContextReturn)

const SURPLUSES_ENDPOINT = 'https://staging-api.wombat.exchange/api/v1/staging/surpluses'

export const useAdminDataContext = () => useContext<IAdminDataContextReturn>(AdminDataContext)
export function AdminDataContextProvider({
  children,
}: {
  children: React.ReactNode
}): ReactElement {
  const { chainId } = useWeb3()
  const tokenPrices = useTokenPrices()
  const masterWombatData = useMasterWombatData()
  const poolData = usePoolData()
  const boostedPoolRewarderData = useBoostedPoolRewarderData()
  const { withoutAccount } = useVotingData()
  const { nextEpochStartTime } = useContextVotingData()
  const currentTime = Math.floor(Date.now() / 1000)
  const currentEpochStartTime = nextEpochStartTime ? nextEpochStartTime - SECONDS_PER_WEEK : 0
  const currentEpochTimePassed = currentTime - currentEpochStartTime
  const [surplusesData, setSurplusesData] = useState<SurplusData[]>([])

  useEffect(() => {
    ;(async () => {
      const response = await axios.get(`${SURPLUSES_ENDPOINT}?chain_id=${chainId}`)
      setSurplusesData(response.data.data?.surplus)
    })()
  }, [chainId])

  const poolRewarderInfos = useMemo(() => {
    return {
      bonusTokenPerSec: masterWombatData.withoutAccount?.bonusTokenPerSec,
      poolRewarderBalances: masterWombatData.withoutAccount?.poolRewarderBalances,
    }
  }, [
    masterWombatData.withoutAccount?.bonusTokenPerSec,
    masterWombatData.withoutAccount?.poolRewarderBalances,
  ])

  const boostedRewarderInfos = useMemo(() => {
    return {
      bonusTokenPerSec: masterWombatData.withoutAccount?.boostedBonusTokenPerSec,
      poolRewarderBalances: masterWombatData.withoutAccount?.boostedRewarderBalances,
    }
  }, [
    masterWombatData.withoutAccount?.boostedBonusTokenPerSec,
    masterWombatData.withoutAccount?.boostedRewarderBalances,
  ])

  const poolInfos = useMemo(() => {
    return masterWombatData.withoutAccount?.poolInfos
  }, [masterWombatData.withoutAccount?.poolInfos])
  const womPrice = useMemo(
    () => (tokenPrices.WOM ? Number(tokenPrices.WOM) : null),
    [tokenPrices.WOM]
  )
  const [requiresBribeAlmostRunout, toggleRequiresBribeAlmostRunout] = useToggle(false)

  const epochEmissionData = useMemo(() => {
    const latestMasterWombatId = LATEST_MASTER_WOMBATS[chainId].masterWombatId
    const initEpochEmissionData: IEpochEmissionData[] = []
    let sumOfGaugeWeight = 0

    const sumOfPoolRewardersEmissionInCurrentEpoch = {
      inUsd: 0,
    }

    const sumOfBoostedRewardersEmissionInCurrentEpoch = {
      inUsd: 0,
    }

    const sumOfWomEmissionInCurrentEpoch = {
      inUsd: 0,
      inWom: 0,
    }
    const sumOfWomEmissionInNextEpoch = {
      inUsd: 0,
      inWom: 0,
    }
    Object.entries(poolInfos || {}).forEach(([poolLabelStr, poolInfo]) => {
      Object.entries(poolInfo).forEach(([assetSymbolStr, assetInfo]) => {
        const poolLabel = poolLabelStr as PoolLabels
        const assetSymbol = assetSymbolStr as TokenSymbol
        const asset = ASSETS[chainId][poolLabel][assetSymbol]

        if (
          POOLS[chainId][poolLabel]?.masterWombatId !== latestMasterWombatId ||
          asset?.delisted ||
          asset?.paused
        )
          return
        const womEmissionInNextEpochStr =
          withoutAccount?.womEmissionsInNextEpoch[poolLabel]?.[assetSymbol]
        const womEmissionInNextEpoch = womEmissionInNextEpochStr
          ? Number(womEmissionInNextEpochStr)
          : 0
        const womEmissionInUsdInNextEpoch = womPrice ? Number(womEmissionInNextEpoch) * womPrice : 0
        sumOfWomEmissionInNextEpoch.inWom += Number(womEmissionInNextEpoch)
        sumOfWomEmissionInNextEpoch.inUsd += Number(womEmissionInUsdInNextEpoch)

        const pool = POOLS[chainId][poolLabel]

        const poolRewardersEmissionInCurrentEpochs: rewardersEmissionInCurrentEpoch[] = []
        const poolRewardersBalanceInfos: IEpochEmissionData[AdminTableColumnId.poolRewardersBalanceInfos] =
          []

        const boostedRewardersEmissionInCurrentEpochs: rewardersEmissionInCurrentEpoch[] = []
        const boostedRewardersBalanceInfos: IEpochEmissionData[AdminTableColumnId.boostedRewardersBalanceInfos] =
          []

        const rateValues: {
          tokenId: number
          tokenSymbol: string
          currentRate: string
          expectedRate: string
        }[] = []

        if (poolRewarderInfos) {
          const rewardTokenSymbols = asset?.poolRewarder?.rewardTokenSymbols
          rewardTokenSymbols?.forEach((rewardTokenSymbol, index) => {
            const rewardTokenDecimals = TOKENS[chainId][rewardTokenSymbol]?.decimals
            const bonusTokenPerSecArray = poolRewarderInfos.bonusTokenPerSec?.[poolLabel][
              assetSymbol
            ]?.map((t) => utils.formatUnits(t, rewardTokenDecimals ?? WAD_DECIMALS))
            const bonusTokenInfos = bonusTokenPerSecArray?.map((t) => t)
            const poolRewardersBalances =
              poolRewarderInfos.poolRewarderBalances?.[poolLabel][assetSymbol]
            let isTpsZero = false
            const bonusTokenPerSec = bonusTokenInfos?.[index]
            const poolRewarderBalance = poolRewardersBalances?.[index]

            if (bonusTokenPerSec && Number(bonusTokenPerSec.toString()) === 0) {
              isTpsZero = true
            }

            // poolRewarder balance
            if (poolRewarderBalance && bonusTokenPerSec) {
              const tokensPerDays = Number(bonusTokenPerSec) * 60 * 60 * 24
              const runoutDays =
                tokensPerDays === 0 ? -1 : Number(poolRewarderBalance) / tokensPerDays
              poolRewardersBalanceInfos.push({
                amount: getDpFormat(poolRewarderBalance),
                tokenSymbol: rewardTokenSymbol,
                runoutDays,
                isTpsZero,
                tokensPerDays,
              })
            }
            // poolRewarder token per second
            if (!bonusTokenInfos || !bonusTokenPerSec || isTpsZero) return

            const rewardTokenPrice = Number(tokenPrices[rewardTokenSymbol])
            const bonusTokenPerWeek = bonusTokenPerSec
              ? Number(bonusTokenPerSec) * SECONDS_PER_WEEK
              : 0

            poolRewardersEmissionInCurrentEpochs.push({
              bonusTokenPerWeek,
              symbol: rewardTokenSymbol,
              rewardTokenPrice,
            })

            const poolRewardersEmissionInUsdInCurrentEpoch = rewardTokenPrice
              ? bonusTokenPerWeek * rewardTokenPrice
              : 0

            sumOfPoolRewardersEmissionInCurrentEpoch.inUsd +=
              poolRewardersEmissionInUsdInCurrentEpoch
          })
        }
        if (boostedRewarderInfos) {
          const rewardTokenSymbols = asset?.boostedPoolRewarder?.rewardTokenSymbols
          rewardTokenSymbols?.forEach((rewardTokenSymbol, index) => {
            const rewardTokenDecimals = TOKENS[chainId][rewardTokenSymbol]?.decimals
            const bonusTokenPerSecArray = boostedRewarderInfos.bonusTokenPerSec?.[poolLabel][
              assetSymbol
            ]?.map((t) => utils.formatUnits(t, rewardTokenDecimals ?? WAD_DECIMALS))
            const bonusTokenInfos =
              bonusTokenPerSecArray ??
              boostedPoolRewarderData?.withoutAccount?.rewardTokenPerSec?.[poolLabel]?.[
                assetSymbol
              ]?.map((t) => t.value)
            const boostedRewardersBalances =
              boostedRewarderInfos.poolRewarderBalances?.[poolLabel][assetSymbol] ??
              boostedPoolRewarderData.withoutAccount?.rewardTokenBalance?.[poolLabel]?.[
                assetSymbol
              ]?.map((t) => t.value)
            let isTpsZero = false
            const bonusTokenPerSec = bonusTokenInfos?.[index]
            const boostedRewarderBalance = boostedRewardersBalances?.[index]

            if (bonusTokenPerSec && Number(bonusTokenPerSec.toString()) === 0) {
              isTpsZero = true
            }

            // boostedRewarder balance
            if (boostedRewarderBalance && bonusTokenPerSec) {
              const tokensPerDays = Number(bonusTokenPerSec) * 60 * 60 * 24
              const runoutDays =
                tokensPerDays === 0 ? -1 : Number(boostedRewarderBalance) / tokensPerDays
              boostedRewardersBalanceInfos.push({
                amount: getDpFormat(boostedRewarderBalance),
                tokenSymbol: rewardTokenSymbol,
                runoutDays,
                isTpsZero,
                tokensPerDays: tokensPerDays,
              })
            }

            // caculate rates
            const nextEpochStartTime = getNextEpochStartTime()
            const remainingTime = nextEpochStartTime - Math.round(Date.now() / 1000)
            const surplusData = surplusesData.filter((s) => s.pid === asset?.pid)
            const surplus =
              surplusData
                .filter(
                  (d) =>
                    d.address.toLowerCase() ===
                    asset?.boostedPoolRewarder?.rewarderAddress?.toString().toLowerCase()
                )[0]
                ?.surplus?.filter(
                  (s) => s.symbol.toLowerCase() === rewardTokenSymbol.toLowerCase()
                ) || []
            const surplusBn = utils.parseUnits(
              surplus[0]?.value ? surplus[0].value : '0',
              rewardTokenDecimals
            )
            const expectedRateBn = surplusBn.div(remainingTime)

            rateValues.push({
              tokenId: index,
              tokenSymbol: rewardTokenSymbol,
              currentRate:
                boostedRewarderInfos.bonusTokenPerSec?.[poolLabel][assetSymbol]?.[
                  index
                ]?.toString() || '0',
              expectedRate: expectedRateBn.toString(),
            })
            // poolRewarder token per second
            if (!bonusTokenInfos || !bonusTokenPerSec || isTpsZero) return

            const rewardTokenPrice = Number(tokenPrices[rewardTokenSymbol])
            const bonusTokenPerWeek = bonusTokenPerSec
              ? Number(bonusTokenPerSec) * SECONDS_PER_WEEK
              : 0

            boostedRewardersEmissionInCurrentEpochs.push({
              bonusTokenPerWeek,
              symbol: rewardTokenSymbol,
              rewardTokenPrice,
            })

            const boostedRewardersEmissionInUsdInCurrentEpoch = rewardTokenPrice
              ? bonusTokenPerWeek * rewardTokenPrice
              : 0

            sumOfBoostedRewardersEmissionInCurrentEpoch.inUsd +=
              boostedRewardersEmissionInUsdInCurrentEpoch
          })
        }
        const rewardRateWadInThisAsset = assetInfo.rewardRate
        if (!rewardRateWadInThisAsset) return
        const allocPoint = withoutAccount?.allocPointOfEachAsset?.[poolLabel]?.[assetSymbol] || '-'
        const womEmissionInCurrentEpoch = Number(
          utils.formatEther(rewardRateWadInThisAsset.mul(SECONDS_PER_WEEK))
        )
        const womEmissionInUsdInCurrentEpoch = womPrice
          ? Number(womEmissionInCurrentEpoch) * womPrice
          : 0
        const bribeTokensPerSecond =
          withoutAccount?.bribeTokenPerSecondOfEachAsset?.[poolLabel]?.[assetSymbol]
        const bribeTokenBalanceInfoOfEachAsset =
          withoutAccount?.bribeTokenBalanceInfoOfEachAsset?.[poolLabel]?.[assetSymbol]
        let totalBribeInUsdInCurrentEpoch = '0'
        const bribeInCurrentEpoch = (bribeTokensPerSecond || [])
          .reduce((prev, { tokenSymbol, value }) => {
            const weeklyEmission = Number(value) * SECONDS_PER_WEEK
            const price = tokenPrices[tokenSymbol]
            const weeklyEmissionInUsd = price ? Number(price) * Number(weeklyEmission) : -1
            if (weeklyEmissionInUsd === -1) {
              totalBribeInUsdInCurrentEpoch = '-'
            } else {
              if (totalBribeInUsdInCurrentEpoch != '-') {
                totalBribeInUsdInCurrentEpoch = utils.formatEther(
                  strToWad(totalBribeInUsdInCurrentEpoch).add(strToWad(String(weeklyEmissionInUsd)))
                )
              }
            }
            const usdValueStr = price ? weeklyEmissionInUsd.toFixed(2) : '-'
            return prev + `, ${weeklyEmission.toFixed(2)}${tokenSymbol}($${usdValueStr})`
          }, '')
          .slice(1)

        const bribeEfficiency = getBribeEfficiencyPercentage(
          womPrice,
          totalBribeInUsdInCurrentEpoch,
          womEmissionInCurrentEpoch
        )
        const projectedWomEmissionInCurrentEpoch =
          (womEmissionInNextEpoch / currentEpochTimePassed) * SECONDS_PER_WEEK
        const projectedBribeEfficiency = nextEpochStartTime
          ? getBribeEfficiencyPercentage(
              womPrice,
              totalBribeInUsdInCurrentEpoch,
              projectedWomEmissionInCurrentEpoch
            )
          : '-'

        let isbribeAlmostRunout = false
        const bribeBalanceInfos =
          bribeTokenBalanceInfoOfEachAsset?.map(({ tokenSymbol, amount }, i) => {
            const bribeTokensPerDays = Number(bribeTokensPerSecond?.[i].value) * 60 * 60 * 24
            const bribeRunoutDays =
              bribeTokensPerDays === 0 ? -1 : Number(amount) / bribeTokensPerDays
            if (
              bribeTokensPerDays &&
              !isbribeAlmostRunout &&
              bribeRunoutDays < MAX_BRIBE_RUNOUT_DAYS
            ) {
              isbribeAlmostRunout = true
            }
            return {
              amount: getDpFormat(amount),
              tokenSymbol: tokenSymbol,
              bribeRunoutDays: bribeRunoutDays,
              tokensPerDays: bribeTokensPerDays,
            }
          }) || []

        const ampFactor = poolData.withoutAccount?.ampFactor[poolLabel] ?? '-'
        const haircutPercentage =
          `${poolData.withoutAccount?.poolFeePercentages[poolLabel]}%` ?? '-'
        const shouldPush =
          (requiresBribeAlmostRunout && isbribeAlmostRunout) || !requiresBribeAlmostRunout
        const gaugeWeight = withoutAccount?.weightOfEachAsset[poolLabel]?.[assetSymbol] || ''
        sumOfWomEmissionInCurrentEpoch.inWom += Number(womEmissionInCurrentEpoch)
        sumOfWomEmissionInCurrentEpoch.inUsd += Number(womEmissionInUsdInCurrentEpoch)
        if (gaugeWeight) sumOfGaugeWeight += Number(gaugeWeight)
        if (shouldPush) {
          initEpochEmissionData.push({
            pid: asset?.pid == undefined ? -1 : asset.pid,
            totalBribeInUsdInCurrentEpoch: `$${getDpFormat(totalBribeInUsdInCurrentEpoch)}`,
            bribeInCurrentEpoch,
            bribeEfficiency,
            projectedBribeEfficiency,
            womEmissionInCurrentEpoch,
            womEmissionInNextEpoch,
            poolInfo: {
              chainId,
              name: pool?.name || '',
              address: pool?.address || '',
            },
            poolRewardersEmissionInCurrentEpochs,
            boostedRewardersEmissionInCurrentEpochs,
            assetInfo: {
              chainId,
              tokenSymbol: assetSymbol,
              address: asset?.address || '',
              boostedPoolRewarder: asset?.boostedPoolRewarder?.rewarderAddress || '',
              bribeRewarderAddress: asset?.bribeRewarder?.rewarderAddress || '',
              poolRewarderAddress: asset?.poolRewarder?.rewarderAddress || '',
              pid: asset?.pid == undefined ? -1 : asset.pid,
            },
            allocPoint,
            poolRewardersBalanceInfos,
            boostedRewardersBalanceInfos,
            bribeBalanceInfos,
            gaugeWeight: withoutAccount?.weightOfEachAsset[poolLabel]?.[assetSymbol] || '',
            bribeInfo:
              asset?.bribeRewarder?.rewardTokenSymbols?.map((tokenSymbolStr, i) => {
                const address = asset?.bribeRewarder?.rewardTokenAddresses?.[i] || ''
                const tokenSymbol = tokenSymbolStr as TokenSymbol
                return {
                  chainId,
                  address,
                  tokenSymbol,
                }
              }) || [],
            haircutPercentage,
            ampFactor,
            remainingSurplus: surplusesData
              ? surplusesData.filter((s) => s.pid === asset?.pid)
              : [],
            rates: {
              boostedPoolRewarder: asset?.boostedPoolRewarder?.rewarderAddress || '',
              values: rateValues,
            },
          })
        }
      })
    })

    // Current/Projected Bribe Efficiency MetaData

    const projectedSumOfWomEmissionInNextEpoch =
      (sumOfWomEmissionInNextEpoch.inWom / currentEpochTimePassed) * SECONDS_PER_WEEK
    const projectedSumOfWomEmissionPerSec = projectedSumOfWomEmissionInNextEpoch / SECONDS_PER_WEEK

    const totalBribeEfficiencyWad = getTotalBribeEfficiencyWad(
      withoutAccount?.womPerSec,
      withoutAccount?.voteAllocation,
      tokenPrices,
      withoutAccount?.bribeTokenPerSecondOfEachAsset
    )

    const projectedBribeEfficiencyWad = getTotalBribeEfficiencyWad(
      projectedSumOfWomEmissionPerSec.toString(),
      withoutAccount?.voteAllocation,
      tokenPrices,
      withoutAccount?.bribeTokenPerSecondOfEachAsset
    )

    const currentBribeEfficiencyPercentage = getPercentageFromTwoWAD(
      totalBribeEfficiencyWad,
      strToWad('1')
    )

    const projectedTotalBribeEfficiencyPercentage = getPercentageFromTwoWAD(
      projectedBribeEfficiencyWad,
      strToWad('1')
    )
    const totalBribeEfficiency = {
      current: currentBribeEfficiencyPercentage,
      projected: projectedTotalBribeEfficiencyPercentage,
    }

    return {
      tableData: initEpochEmissionData,
      metaData: {
        sumOfGaugeWeight,
        sumOfPoolRewardersEmissionInCurrentEpoch,
        sumOfBoostedRewardersEmissionInCurrentEpoch,
        sumOfWomEmissionInCurrentEpoch,
        sumOfWomEmissionInNextEpoch,
        totalBribeEfficiency,
      },
    }
  }, [
    chainId,
    poolInfos,
    currentEpochTimePassed,
    withoutAccount?.womPerSec,
    withoutAccount?.voteAllocation,
    withoutAccount?.bribeTokenPerSecondOfEachAsset,
    withoutAccount?.womEmissionsInNextEpoch,
    withoutAccount?.allocPointOfEachAsset,
    withoutAccount?.bribeTokenBalanceInfoOfEachAsset,
    withoutAccount?.weightOfEachAsset,
    tokenPrices,
    womPrice,
    nextEpochStartTime,
    poolData.withoutAccount?.ampFactor,
    poolData.withoutAccount?.poolFeePercentages,
    requiresBribeAlmostRunout,
    poolRewarderInfos,
    boostedRewarderInfos,
    boostedPoolRewarderData.withoutAccount?.rewardTokenPerSec,
    boostedPoolRewarderData.withoutAccount?.rewardTokenBalance,
    surplusesData,
  ])
  return (
    <AdminDataContext.Provider
      value={{ requiresBribeAlmostRunout, toggleRequiresBribeAlmostRunout, epochEmissionData }}
    >
      {children}
    </AdminDataContext.Provider>
  )
}
