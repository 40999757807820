import { getContract } from '@wagmi/core'
import { useState } from 'react'
import { MASTER_WOMBAT_V3_ABI } from '../../constants/contract/abis/masterWombat'
import { MasterWombatId, MASTER_WOMBATS } from '../../constants/contract/masterWombat'
import { useModalContext } from '../../context/ModalContext'
import { useWeb3 } from '../../context/Web3Context'
import { ModalId } from '../../interfaces/modal'
import { calculateGasMargin } from '../../utils'
import { useMasterWombatMigrationData } from '../../store/MigrationDetection/hooks'
import { useAppDispatch } from '../../store/hooks'
import updateAllDelistedData from '../../store/MigrationDetection/thunks/updateAllDelistedData'
type Props = {
  isWaiting: boolean
  isDone: boolean
  handleMigrate: (MasterWombatId: MasterWombatId) => Promise<void>
}
const useMasterWombatMigrate = (): Props => {
  const { chainId, signer, account } = useWeb3()
  const [isWaiting, setIsWaiting] = useState(false)
  const [isDone, setIsDone] = useState(false)
  const dispatch = useAppDispatch()
  const masterWombatMigrationData = useMasterWombatMigrationData()
  const {
    actions: { openModal },
  } = useModalContext()
  const handleMigrate = async (masterWombatId: MasterWombatId) => {
    setIsDone(false)
    setIsWaiting(true)
    let transaction
    const masterWombat = MASTER_WOMBATS[chainId]?.[masterWombatId]
    let masterWombatContract
    if (masterWombat) {
      masterWombatContract = getContract({
        ...masterWombat?.get(),
        abi: MASTER_WOMBAT_V3_ABI,
        signerOrProvider: signer || undefined,
      })
    }
    const pidsBN = masterWombatMigrationData[masterWombatId]?.stakedPidsBN
    if (!masterWombat || !masterWombatContract || !pidsBN) {
      setIsWaiting(false)
      return
    }
    try {
      const estimatedGas = await masterWombatContract.estimateGas.migrate(pidsBN)
      transaction = await masterWombatContract.migrate(pidsBN, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      await transaction.wait()
      openModal({
        currentModalId: ModalId.TRANSACTION_SUBMITTED,
        payload: { transactionHashes: [transaction.hash] },
      })
      await dispatch(updateAllDelistedData({ chainId, account }))
    } catch (err) {
      console.error(err)
      openModal({ currentModalId: ModalId.TRANSACTION_FAILED })
    } finally {
      setIsWaiting(false)
    }
  }
  return { handleMigrate, isWaiting, isDone }
}

export default useMasterWombatMigrate
