/* eslint-disable @next/next/no-img-element */

import { SupportedChainId } from '../../../../constants/web3/supportedChainId'
import { getExplorerLink } from '../../../../utils/getBscScanLink'
import TooltipNum from '../../../TooltipNum'

export const HeaderWithTotalValue = ({
  title,
  totalValue,
  totalValueInUsd,
}: {
  title: string
  totalValue?: string | number
  totalValueInUsd?: string | number
}) => {
  return (
    <button className="capitalize leading-4">
      {title}
      <div className={` flex flex-col  ${totalValueInUsd && 'leading-4'} mt-2`}>
        <span>
          Total: {totalValue ? <TooltipNum millified amount={totalValue}></TooltipNum> : '-'}
        </span>
        {totalValueInUsd && (
          <span>
            ($
            <TooltipNum millified amount={totalValueInUsd} />)
          </span>
        )}
      </div>
    </button>
  )
}
export const NumberTab = ({
  topLeft,
  bottomLeft,
  right,
}: {
  topLeft?: {
    symbol?: {
      isleft: boolean
      value: string
    }
    value: string | number
  }
  bottomLeft?: {
    symbol?: {
      isleft: boolean
      value: string
    }
    value: string | number
  }
  right?: {
    symbol?: {
      isleft: boolean
      value: string
    }
    value: string | number
  }

  usdValue?: string | number
  percent?: string | number
  value?: string | number
}) => {
  return (
    <div className="flex justify-start">
      <div className={` flex flex-col  ${bottomLeft && 'leading-4'} mr-2`}>
        {topLeft && (
          <span>
            {topLeft.symbol && topLeft.symbol.isleft && topLeft.symbol.value}
            <TooltipNum millified amount={topLeft.value}></TooltipNum>
            {topLeft.symbol && !topLeft.symbol.isleft && topLeft.symbol.value}
          </span>
        )}
        {bottomLeft && (
          <span>
            ({bottomLeft.symbol && bottomLeft.symbol.isleft && bottomLeft.symbol.value}
            <TooltipNum millified amount={bottomLeft.value} />
            {bottomLeft.symbol && !bottomLeft.symbol.isleft && bottomLeft.symbol.value})
          </span>
        )}
      </div>
      {right && (
        <span>
          [{right.symbol && right.symbol.isleft && right.symbol.value}
          <TooltipNum amount={right.value}></TooltipNum>
          {right.symbol && !right.symbol.isleft && right.symbol.value}]
        </span>
      )}
    </div>
  )
}
export const CopyAndExplorerTab = ({
  children,
  address,
  chainId,
}: {
  children: React.ReactNode
  address: string
  chainId: SupportedChainId
}) => {
  return (
    <div className="flex">
      <button onClick={() => navigator.clipboard.writeText(address)} className=" cursor-copy ">
        {children}
      </button>
      <button
        onClick={() => window.open(getExplorerLink(address, 'address', chainId))}
        className="flex items-center"
      >
        <img src={'assets/icons/blank-page.svg'} height={24} width={24} />
      </button>
    </div>
  )
}
