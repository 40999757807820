import useToggle from '../../../hooks/useToggle'
import Button from '../../Button'
import { AdminDataContextProvider } from '../AdminDataContext'
import { AdminTable } from '../Table/AdminTable'

function AdminTableButton() {
  const [active, toggle] = useToggle(false)
  return (
    <>
      {active && (
        <AdminDataContextProvider>
          <div className="fixed top-0  left-0 h-full w-full overflow-scroll bg-white">
            <AdminTable handleClose={toggle} />
          </div>
        </AdminDataContextProvider>
      )}
      <Button onClick={toggle} className="rounded-lg !text-sm">
        {active ? 'Close Admin Table' : 'Show Admin Table'}
      </Button>
    </>
  )
}

export default AdminTableButton
