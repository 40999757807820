import React, { useEffect, useState } from 'react'
import { useWeb3 } from '../../context/Web3Context'
import { HexString } from '../../interfaces/contract'
import { getChecksummedAddress } from '../../utils'

function MockAccountInput() {
  const [mockAccountInput, setMockAccountInput] = useState('')
  const [enableMockAccount, setEnableMockAccount] = useState(false)
  const { setMockAccount } = useWeb3()

  useEffect(() => {
    if (getChecksummedAddress(mockAccountInput) && enableMockAccount) {
      setMockAccount(mockAccountInput as HexString)
    } else {
      setMockAccount('' as HexString)
    }
  }, [enableMockAccount, mockAccountInput, setMockAccount])

  return (
    <div className="z-10 flex gap-2 rounded-lg border-2 border-wombatPurple3 bg-wombatCream p-2">
      <input
        type="text"
        placeholder="View Page As Address"
        className="h-8 w-full rounded-lg bg-transparent font-Work text-base font-semibold text-wombatBrown1 focus:outline-none"
        value={mockAccountInput}
        onChange={(e) => {
          setMockAccountInput(e.target.value)
        }}
      />
      <input
        type="checkbox"
        className="w-[18px]"
        onChange={() => setEnableMockAccount((prev) => !prev)}
      />
    </div>
  )
}

export default MockAccountInput
